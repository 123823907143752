export const clientNameFooter = "TEO BALIEIRO";
export const titleMain = "";
export const subtitleMain = "";

export const aspectRatioLogoAbout = '2'; // width/height
export const aspectRatioLogoMain = '1'; // width/height

export const borderRadiusButtons = '10px';

export const nomeFornecedorCompleto = "C Ja Educacao e Meio Ambiente LTDA";
export const nomeFornecedor = "Cultivo do Jardim do Amor";
export const artigo = "a";
export const artigoCaps = "A";

export const footerSocialData = [
  {
    type: "material-community",
    name: "instagram",
    link: "https://www.instagram.com/cultivodojardimdoamor/",
    profile: "@cultivodojardimdoamor"
  },
  {
    type: "material-community",
    name: "youtube",
    link: "https://www.youtube.com/@teobalieiro",
  },
  {
    type: "material-community",
    name: "whatsapp",
    link: "https://wa.me/5512997856211",
    number: "(12) 997856211"
  },
];

export const drawerClosedLinks = [
  {
    title: "Fale Comigo",
    link: "https://wa.me/5512997856211",
  },
  {
    title: "Site CJA",
    link: "http://www.cultivodojardimdoamor.com.br/",
  },
  {
    title: "Site Yoganaya",
    link: "http://www.yoganaya.com.br/",
  },
];

export const linkAppleStore = null
export const linkPlayStore = null

export const hasOutsideAboutPage = false;
export const drawerOpenedLinks = [];