import { nomeFornecedorCompleto, nomeFornecedor, artigo, artigoCaps } from '../data';

const clausula6_4 = "6.4. O pagamento será realizado pelo ALUNO, por meio do Aplicativo Web.";
const clausula6_5 = `6.5. O ALUNO pode desistir do produto/serviço contratado, no prazo legal de 7 dias a contar de sua assinatura ou do ato de recebimento do produto ou serviço. Cabe ao ALUNO solicitar, formalmente por e-mail à ${nomeFornecedor}, a desistência da contratação para evitar próximos faturamentos. Ressalta-se que não basta para a referida providência o simples cancelamento pelo Aplicativo Web, sendo imprescindível nesse caso o envio do e-mail.`;
const clausula6_6 = "6.6. O não pagamento da primeira parcela ajustada será entendido como cancelamento automático da contratação e restabelecerá as partes ao seu status original.";
const clausula6_7 = "6.7. O não pagamento de parcelas previamente ajustadas, após a contratação, constituirá o inadimplente, imediatamente em mora e acarretará no cancelamento imediato do serviço.";
const clausula6_8 = `6.8. ${artigoCaps} ${nomeFornecedor} se resguarda, em caso de inadimplemento pelo ALUNO, ao direito de suspender, interromper, cancelar ou inviabilizar, de qualquer forma, a utilização do serviço.`;
const clausula6_9 = "6.9. Os valores inadimplidos serão atualizados, até seu pagamento mediante correção monetária e incidência de juros legais.";
const clausula6_10 = `6.10. O ALUNO poderá encerrar a contratação do serviço a qualquer momento. Cabe a ele solicitar, formalmente nos mesmos termos da cláusula 6.5, por e-mail à ${nomeFornecedor}, o cancelamento de sua assinatura, antes da próxima cobrança para evitar o faturamento.`;
const clausula6_10_1 = `6.10.1. Em caso de cancelamento por iniciativa do ALUNO, serão reembolsados pelo pel${artigo} ${nomeFornecedor} 50% dos valores já quitados, referentes ao serviço não realizado. Nesse caso, ${artigo} ${nomeFornecedor} ainda reserva-se ao direito de reter eventuais valores relacionados a despesas em geral, devidamente justificados.`;
const clausula6_10_2 = `6.10.2. No caso de contratação de longo prazo, com previsão de pagamento mensal, o cancelamento na forma do 6.5 pelo ALUNO ocasionará o encerramento da cobrança. De toda forma, nesse caso, a empresa terá o direito de cobrar uma taxa de cancelamento no percentual de 10% sobre o valor total.`;

export const privacyData = [
  {
    subtitle: "1. PESSOAS ENVOLVIDAS",
    paragraphs: [
      {
        text: "1.1. A relação comercial e de prestação de serviço tratada nestes Termos de Uso envolverá as seguintes Pessoas/Partes:",
      },
      {
        text: `a. ${nomeFornecedorCompleto}: É a empresa fornecedora de material educacional, responsável pela criação, gestão e comercialização de conteúdo digital, como aulas gravadas, aulas ao vivo ou cursos. A partir deste momento, será denominada, simplesmente, ${nomeFornecedor}.`,
      },
      {
        text: `b. ALUNOS DOS CURSOS/PRODUTOS DIGITAIS/PLANOS DE ASSINATURA OFERECIDOS PEL${artigoCaps} ${nomeFornecedor}: São pessoas físicas ou jurídicas que utilizarão e se beneficiarão dos cursos e/ou planos de assinatura criados, geridos e comercializados pela empresa fornecedora. Tratam-se dos consumidores finais dos cursos/produtos digitais e/ou planos de assinatura, que manterão relação exclusiva com a empresa fornecedora. A partir deste momento, serão denominados, simplesmente, "ALUNOS".`,
      },
    ],
  },
  {
    subtitle: "2. OBJETO (ESCOPO DO SERVIÇO)",
    paragraphs: [
      {
        text: `2.1. Os presentes Termos e Condições de Uso são aplicáveis a todos os ALUNOS dos serviços de mídia on-line e distribuição de conteúdo fornecido por ${nomeFornecedor}, por meio de Aplicativo Mobile, Aplicativo Web e Streaming voltado para a prestação de serviço de viabilização de distribuição de conteúdo digital por meio de aulas gravadas, aulas online e cursos.`,
      },
      {
        text: `2.2. Trata-se de um acordo legal entre ${nomeFornecedor} e seus ALUNOS, que vincula as partes e tem, para todos os fins, força de contrato. Assim, nos termos aqui delineados, estabelecem-se os direitos e deveres que devem ser observados antes, durante e após a relação comercial.`,
      },
      {
        text: `2.3. Para que possa utilizar quaisquer dos serviços disponibilizados, o ALUNO declara expressamente ter lido, compreendido e anuído com todos os termos e condições aqui estabelecidos.`,
      },
      {
        text: `2.4. É importante considerar, também, que o conteúdo dos presentes termos pode ser modificado periodicamente, visando ao aperfeiçoamento do serviço. Caberá, assim, ao ALUNO revisar as políticas de forma regular, as quais serão, em todos os casos, sempre publicadas da forma usual.`,
      },
    ],
  },
  {
    subtitle:
      "3. UTILIZAÇÃO DO SERVIÇO",
    paragraphs: [
      {
        text: `3.1. Os ALUNOS que utilizarem de qualquer forma os serviços devem ter, pelo menos, 18 (dezoito) anos de idade. Menores de idade poderão acompanhar as aulas ou ter acesso ao conteúdo, desde que devidamente assistidos/acompanhados por um responsável.`,
      },
      {
        text: `3.2. ${artigoCaps} ${nomeFornecedor} não exige que os acessos, pelos ALUNOS, sejam realizados em qualquer local específico. Ao acessar os Serviços, o ALUNO é o único responsável pelo cumprimento das leis e regulamentos do local de sua jurisdição.`,
      },
      {
        text: `3.3. Os serviços podem e devem ser utilizados unicamente para o objetivo de prestação de serviço de viabilização de distribuição de conteúdo digital por meio de aulas gravadas, aulas online e cursos. É expressamente vedado qualquer uso do material compartilhado, para quaisquer outros fins que não aqueles formalizados nos presentes Termos de Uso.`,
      },
      {
        text: `3.4. Será concedida ao ALUNO, pel${artigo} ${nomeFornecedor}, uma licença limitada e não exclusiva para acessar e utilizar os serviços ou o conteúdo digital para seus próprios fins pessoais e, nunca, comerciais. Nisso está incluído o direito de visualizar o conteúdo disponibilizado no web site ou no APP.`,
      },
      {
        text: `3.5. O ALUNO é o único responsável por decidir se os serviços oferecidos são adequados para seus próprios fins, se atendem às suas necessidades e por, eventualmente, solicitar o cancelamento, desde que respeitados os ajustes de preço e obrigações estabelecidas com ${artigo} ${nomeFornecedor}.`,
      },
      {
        text: `3.6. O ALUNO não pode violar ou tentar violar a segurança do Serviço, incluindo, de forma exemplificativa, sem limitação, (i) acessar dados a ele não destinados ou logar em servidor ou conta não autorizada; (ii) verificar ou testar a vulnerabilidade do sistema ou rede ou, de qualquer forma, violar medidas de segurança ou autenticação; (iii) utilizar-se de robôs, tentar ou prejudicar o serviço por disseminação de vírus, sobrecarga, “inundação”, “spam”, “mailbombing” ou “travamento” ou qualquer prática similar.`,
      },
      {
        text: `3.7. Eventuais violações da segurança ou conduta inadequada poderão resultar em responsabilização civil ou criminal.`,
      },
    ],
  },
  {
    subtitle:
      "4. DADOS PESSOAIS E POLÍTICAS DE PRIVACIDADE",
    paragraphs: [
      {
        text: `4.1. O ALUNO é o responsável pelas informações pessoais inseridas ou utilizadas para a contratação dos serviços. Com a disponibilização destas informações, será outorgado, automaticamente, o consentimento expresso para o tratamento de seus dados pelo fornecedor dos serviços e seus parceiros, desde que dentro do escopo e finalidades formalizadas nos presentes Termos de Uso.`,
      },
      {
        text: `4.2. Com a adesão aos presentes Termos de uso, o ALUNO outorga, em complementação à autorização de tratamento de dados pessoais do item anterior, também e de forma expressa e gratuita, a autorização do uso de sua imagem e voz constantes em fotos, gravações e filmagens realizadas na prestação do serviço e em conformidade com o escopo e finalidades formalizadas nos presentes Termos de Uso.`,
      },
      {
        text: `4.3. ${artigoCaps} ${nomeFornecedor} se reserva ao direito de decidir se as informações inseridas pelos ALUNOS são apropriadas/adequadas e estão em conformidade com os Termos de Uso, suas políticas, as políticas do desenvolvedor, leis e regulamentos aplicáveis.`,
      },
      {
        text: `4.4. Ao ALUNO será exigido que forneça certas informações, como nome completo, CPF, celular e endereço de e-mail válido. Essas informações necessitam ser fidedignas e mantidas atualizadas.`,
      },
      {
        text: `4.5. O ALUNO é responsável por manter a confidencialidade de seus dados de acesso, incluindo nome de usuário e senha.`,
      },
      {
        text: `4.6. O ALUNO se responsabiliza, integralmente, pelo uso indevido de seus dados de acesso decorrente de desídia, fornecimento (consciente ou não) ou qualquer forma de não atendimento à cláusula anterior.`,
      },
      {
        text: `4.7. Se ocorrer qualquer suspeita de que outra pessoa está utilizando indevidamente a sua conta, o ALUNO deve, imediatamente, buscar reestabelecer uma senha segura ou, sendo o caso, comunicar ${artigo} ${nomeFornecedor}, para as medidas necessárias.`,
      },
      {
        text: `4.8. O ALUNO consente em receber comunicações eletrônicas d${artigo} ${nomeFornecedor}, incluindo e-mails, mensagens por aplicativos de conversa, telefone, marketing, transações e outras informações relacionadas aos serviços e à sua conta.`,
      },
    ],
  },
  {
    subtitle:
      "5. RESPONSABILIDADES DE FORNECIMENTO (responsabilidades do Fornecedor)",
    paragraphs: [
      {
        text: `5.1. ${artigoCaps} ${nomeFornecedor} deverá disponibilizar o acesso ao ambiente virtual das aulas online por meio de login e senha, gerados após confirmação do cumprimento dos pré-requisitos por parte do ALUNO.`,
      },
      {
        text: `5.2. O acesso ao serviço funcionará normalmente 24 horas por dia e 07 dias por semana.  Podem ocorrer pequenas interrupções de forma temporária para ajustes, manutenção, alteração de servidores, falhas operacionais ou em razão de força maior.`,
      },
      {
        text: `5.3. ${artigoCaps} ${nomeFornecedor} realizará os melhores esforços e iniciativas para manter os serviços em operação sem interrupções, nos termos acima referidos. Não obstante, nenhuma hipótese garante a disponibilidade e continuidade permanente do serviço. Eventuais indisponibilidades ou a descontinuação permanente do serviço não geram qualquer direito de indenização ao ALUNO, salvo aquelas relacionadas a valores pagos de forma antecipada, nos termos ajustados entre os contratantes.`,
      },
      {
        text: `5.4. Considerando a existência de variáveis​​, como velocidade de conexão e download, localização, etc., ${artigo} ${nomeFornecedor} não garante a resolução e qualidade do conteúdo digital durante qualquer transmissão.`,
      },
    ],
  },
  {
    subtitle: "6. ASSINATURAS, CANCELAMENTOS, PAGAMENTOS E COBRANÇAS",
    paragraphs: [
      {
        text: `6.1. Ao adquirir um curso e/ou plano de assinatura, o ALUNO concorda expressamente em relação à cobrança em conformidade com o plano selecionado e/ou curso e modo/tempo de pagamento designado.`,
      },
      {
        text: `6.2. Os recibos serão enviados assim que a cobrança for bem-sucedida na conta de e-mail registrada. Com relação aos planos de assinatura, a assinatura continuará em vigor, a menos que o ALUNO a cancele, encerre sua conta ou se torne inadimplente.`,
      },
      {
        text: `6.3. ${artigoCaps} ${nomeFornecedor} e o ALUNO poderão ajustar valores e qualidades de fornecimento, com adesão a planos mensais.`,
      },
      {
        text: `${clausula6_4}`,
      },
      {
        text: `${clausula6_5}`,
      },
      {
        text: `${clausula6_6}`,
      },
      {
        text: `${clausula6_7}`,
      },
      {
        text: `${clausula6_8}`,
      },
      {
        text: `${clausula6_9}`,
      },
      {
        text: `${clausula6_10}`,
      },
      {
        text: `${clausula6_10_1}`
      },
      {
        text: `${clausula6_10_2}`
      },
    ],
  },
  {
    subtitle: "7. DECLARAÇÃO DE CIÊNCIA E RESPONSABILIDADES EXPRESSAS DO CLIENTE",
    paragraphs: [
      {
        text: `7.1. Para realização de qualquer atividade física, incluindo aquelas que são escopo do presente serviço, é necessário que o ALUNO realize as devidas consultas médicas com antecedência, para verificar se está apto ou não para a execução das atividades.`,
      },
      {
        text: `7.2. Tal precaução é necessária a TODOS OS ALUNOS, mas, em especial, àqueles com deficiências de qualquer natureza (físicas, motoras ou mentais) ou, ainda, com histórico de doenças preexistentes ou lesões de qualquer natureza.`,
      },
      {
        text: `7.3. Ao aderir aos presentes Termos de uso, o ALUNO atesta, reconhece e declara que está física e mentalmente apto à prática de exercícios físicos, isentando o Fornecedor de qualquer responsabilidade relacionada a lesões ou danos de toda espécie ocorridos pela prática dessa atividade física.`,
      },
      {
        text: `7.4. O ALUNO deverá informar ${artigoCaps} ${nomeFornecedor}, de forma prévia, a existência de qualquer problema de saúde ou contraindicação médica.`,
      },
      {
        text: `7.5. Antes de iniciar qualquer atividade ou contratar o Serviço oferecido pel${artigoCaps} ${nomeFornecedor}, o ALUNO deve, além da consulta médica, ter ciência e respeitar suas limitações de qualquer natureza, assim como, de suas condições pessoais, relacionadas a peso, altura, estilo de vida, sedentarismo ou hábitos de qualquer espécie.`,
      },
      {
        text: `7.6. Também é necessário ao ALUNO que pesquise previamente quanto aos diferentes métodos existentes relacionados às aulas e curss e qual melhor se aplica a si, que avalie conscientemente a expectativa do que almeja e é possível ser buscado como resultado.`,
      },
      {
        text: `7.7. Os serviços oferecidos não se destinam ou confundem, de forma alguma, como aconselhamento médico ou como substituto de tratamento médico.`,
      },
      {
        text: `7.8. ${artigoCaps} ${nomeFornecedor} não será responsável, de forma alguma, por qualquer resultado não atingido pelo ALUNO. A responsabilidade d${artigoCaps} ${nomeFornecedor} consiste, unicamente, na prestação de informações, aulas, instruções e orientações de seu método, de forma online.`,
      },
      {
        text: `7.9. O ALUNO é responsável em relação aos equipamentos e software necessários para utilização do serviço, seguindo os requisitos exigidos e com acesso à internet. Também é responsável por todo o equipamento necessário, incluindo roupas, tapete, etc. e pela segurança e adequação do ambiente físico no qual realizará a prática.`,
      },
    ],
  },
  {
    subtitle: "8. PROPRIEDADE INTELECTUAL",
    paragraphs: [
      {
        text: `8.1. A ${nomeFornecedor} e quaisquer outras marcas ou nomes comerciais e quaisquer variações dos mesmos, são e devem permanecer como propriedades exclusivas do Fornecedor, sendo proibido qualquer uso não autorizado.`,
      },
      {
        text: `8.2. O ALUNO não pode vender ou modificar o conteúdo dos serviços ou mídia/material didático disponibilizado, sendo também vedada a reprodução, execução e exibição pública, assim como a distribuição ou utilização dos serviços de qualquer forma ou para qualquer finalidade, que não aquela estabelecida nos presentes termos de Uso.`,
      },
      {
        text: `8.3. O ALUNO concorda, expressamente, que ${artigo} ${nomeFornecedor} possui, detém e retém todos os direitos sobre os serviços oferecidos, sendo o conteúdo de propriedade e controle exclusivo da empresa fornecedora.`,
      },
      {
        text: `8.4. Todo o material disponibilizado é protegido por direitos autorais, marca comercial e outras propriedades intelectuais comuns e estatutárias e legais.`,
      },
    ],
  },
  {
    subtitle:
      "9. ISENÇÕES DE RESPONSABILIDADE DA EMPRESA DESENVOLVEDORA",
    paragraphs: [
      {
        text: `9.1. O serviço será viabilizado pel${artigo} ${nomeFornecedor}, pela sua contratação própria e sem transferência de obrigações ao ALUNO, do software/plataforma adequadas, perante a empresa Desenvolvedora.`,
      },
      {
        text: `9.2. Em relação ao presente serviço, a desenvolvedora do software é a BLIX SOLUÇÕES EM TECNOLOGIA, inscrita no CNPJ/MF sob o n° 43.589.960/0001-41.`,
      },
      {
        text: `9.3. A relação estabelecida entre ${artigo} ${nomeFornecedor} e a empresa Desenvolvedora é simplesmente comercial, de contratação de serviços com Termos de Uso específicos. Não são, entre si, empregados, associados, subcontratados representantes, fiadores, seguradores ou garantidores uma da outra.`,
      },
      {
        text: `9.4. A empresa Desenvolvedora não será, em qualquer hipótese, responsável pelo relacionamento comercial ou de prestação de serviços mantidos entre a empresa fornecedora dos serviços e seus ALUNOS. Apenas ${artigo} ${nomeFornecedor} e nunca a Desenvolvedora será responsável por cumprir as obrigações inerentes à relação comercial mantida com seus ALUNOS, incluindo sem limitação, todos os danos, prejuízos, acidentes e ocorrências na prestação dos serviços.`,
      },
      {
        text: `9.5. A empresa Desenvolvedora não é prestadora dos serviços de aulas online ou disponibiliza qualquer conteúdo didático. Tais serviços são prestados pel${artigo} ${nomeFornecedor} de forma independente e autônoma. Nesses termos, a desenvolvedora não garante nem se responsabiliza quanto à qualidade da prestação dos serviços prestados pel${artigoCaps} ${nomeFornecedor} aos seus ALUNOS, do material divulgado ou problemas oriundos de demora ou de má prestação.`,
      },
    ],
  },
  {
    subtitle: "10. CONDIÇÕES GERAIS",
    paragraphs: [
      {
        text: `10.1. Os serviços serão fornecidos “tal como estão”, sem garantia de qualquer tipo.`,
      },
      {
        text: `10.2. ${artigoCaps} ${nomeFornecedor} e ALUNO isentam-se quanto a quaisquer garantias e condições relativas aos serviços, expressas ou implícitas, incluindo condições de comercialização, de qualidade satisfatória ou adequação a um propósito específico.`,
      },
      {
        text: `10.3. ${artigoCaps} ${nomeFornecedor} não garante que estes atenderão aos requisitos do ALUNO ou serão benéficos, que a prestação dos serviços será ininterrupta ou sem erros.`,
      },
      {
        text: `10.4. A transmissão de dados ou informações pela internet ou em outras redes publicamente acessíveis nunca pode ser considerada 100% segura e está sujeita a perdas, intercepção, alterações ou divulgações indevidas. Assim, ${artigo} ${nomeFornecedor} não assume qualquer responsabilidade por quaisquer danos que os ALUNOS possam sofrer em decorrência de qualquer transmissão na internet ou rede publicamente acessível.`,
      },
      {
        text: `Como condição de participação e contratação do serviço, o ALUNO declara que leu e compreendeu os presentes TERMOS na íntegra, assumindo responsabilidade exclusiva pela prática on-line das aulas e demais disposições ajustadas.`,
      },
    ],
  },
];